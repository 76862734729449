import request from '@/utils/request'

const companyApi = {
    base: '/api/v1/rest/companies',
    query: '/api/v1/company/query',
    delete: '/api/v1/company/delete'
}

export function companys(param) {
    const url = request.requestFormat(companyApi.base, param)
    return request({
        url,
        method: 'get'
    })
}

export function companyQuery(param) {
    const url = request.requestFormat(companyApi.query, param)
    console.log('url', url)
    return request({
        url,
        method: 'get'
    })
}

export function companyAdd(data) {
    return request({
        url: companyApi.base,
        method: 'post',
        data
    })
}

export function companyGet(id) {
    return request({
        url: companyApi.base + `/${id}`,
        method: 'get'
    })
}

export function companyPatch(id, data) {
    return request({
        url: companyApi.base + `/${id}`,
        method: 'patch',
        data
    })
}

export function getCompanyContract (id) {
  return request({
    url: companyApi.base + '/' + id + '/contractPhotos',
    method: 'get'
  })
}

export function putCompanyContract (id, data) {
  return request({
    url: companyApi.base + '/' + id + '/contractPhotos',
    method: 'put',
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data
  })
}

export function companyPut(id, data) {
    return request({
        url: companyApi.base + `/${id}`,
        method: 'put',
        data
    })
}

export function companyDelete(param) {
    const url = request.requestFormat(companyApi.delete, param)
    return request({
        url,
        method: 'get'
    })
}
