import request from '@/utils/request'

const shopBrokerApi = {
    base: '/api/v1/rest/shopBrokers',
    query: '/api/v1/shopBroker/query',
    delete: '/api/v1/shopBroker/delete',
    edit: '/api/v1/shopBroker/edit',
    findaudit: '/api/v1/shopBroker/findaudit',
    scanJoin: '/api/v1/shopBroker/scanJoin'
}

export function shopBrokers(param) {
    const url = request.requestFormat(shopBrokerApi.base, param)
    return request({
        url,
        method: 'get'
    })
}

export function shopBrokerQuery(param) {
    const url = request.requestFormat(shopBrokerApi.query, param)
    return request({
        url,
        method: 'get'
    })
}

export function shopBrokerEdit(data) {
    return request({
        url: shopBrokerApi.edit,
        method: 'post',
        data
    })
}

export function shopBrokerGet(id) {
    return request({
        url: shopBrokerApi.base + `/${id}`,
        method: 'get'
    })
}

export function shopBrokerPatch(id, data) {
    return request({
        url: shopBrokerApi.base + `/${id}`,
        method: 'patch',
        data
    })
}

export function shopBrokerPut(id, data) {
    return request({
        url: shopBrokerApi.base + `/${id}`,
        method: 'put',
        data
    })
}

export function shopBrokerDelete(id) {
    return request({
        url: shopBrokerApi.base + `/${id}`,
        method: 'delete'
    })
}

export function shopBrokerFindaudit(param) {
    const url = request.requestFormat(shopBrokerApi.findaudit, param)
    return request({
        url,
        method: 'get'
    })
}

export function shopBrokerScanJoin(param) {
    const url = request.requestFormat(shopBrokerApi.scanJoin, param)
    return request({
        url,
        method: 'get'
    })
}
