<template>
  <a-drawer
    title="门店详情"
    placement="right"
    :width="drawerWidth"
    class="myDescription"
    :visible="visible"
    @close="handleCancel"
  >
    <a-card :bordered="false">
      <a-spin :spinning="loading">
        <a-descriptions title="基本信息">
          <a-descriptions-item label="门店名称" span="3">{{ model.name | textFilter }}</a-descriptions-item>
          <a-descriptions-item label="所在地区" span="3">{{
            model.area ? model.area.join('') : '暂无信息'
          }}</a-descriptions-item>
          <a-descriptions-item label="详细地址" span="3">{{ model.address | textFilter }}</a-descriptions-item>
          <a-descriptions-item label="门店代码" span="3">{{ model.code | textFilter }}</a-descriptions-item>
          <a-descriptions-item label="店长姓名" span="3">{{ shopOwner.name | textFilter }}</a-descriptions-item>
          <a-descriptions-item label="店长电话" span="3">{{ shopOwner.phone | textFilter }}</a-descriptions-item>
          <a-descriptions-item label="拓展人" span="3">{{ expand.name | textFilter }}</a-descriptions-item>
          <a-descriptions-item label="拓展人电话" span="3">{{ expand.phone | textFilter }}</a-descriptions-item>
          <a-descriptions-item label="创建时间" span="3">{{ model.createTime | textFilter }}</a-descriptions-item>
        </a-descriptions>
        <a-table 
          :rowKey="record => record.id" 
          :loading="tableLoading" 
          :columns="columns" 
          :data-source="list" 
          :pagination="pagination" 
          @change="tableChange"></a-table>
      </a-spin>
    </a-card>
  </a-drawer>
</template>

<script>
import { shopGet } from '@/api/shop'
import { shopBrokerQuery } from '@/api/shopbroker'
export default {
  data() {
    return {
      noticeTitle: '门店管理',
      visible: false,
      loading: false,
      tableLoading: false,
      id: null,
      model: {},
      expand: {},
      shopOwner: {},
      list: [],
      pagination: {
        current: 1,
        results: 10
      },
      form: this.$form.createForm(this),
      columns: [
        {
          title: '经纪人',
          dataIndex: 'broker.name'
        },
        {
          title: '手机号',
          dataIndex: 'broker.phone'
        }
      ]
    }
  },
  created() {},
  methods: {
    showModal(record) {
      this.visible = true
      this.id = record.id
      this.list = []
      this.dataFetch()
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.model = {}
      this.pagination = {
        current: 1,
        results: 10
      }
    },
    dataFetch() {
      this.loading = true
      shopGet(this.id).then(res => {
        if (res.id) {
          this.model = res
          this.shopOwner = res._embedded.shopOwner
          this.expand = res._embedded.user
          this.model.area = [res.province, res.city, res.district, res.street]
          this.listFetch()
        }
      })
    },
    listFetch() {
      this.tableLoading = true
      const param = {
        shopId: this.id,
        page: this.pagination.current - 1,
        size: this.pagination.results
      }
      shopBrokerQuery(param)
        .then(res => {
          if (res.success) {
            this.list = res.data.content.filter(item => {
              return item.status === 1
            })
            this.pagination = {
              current: res.data.number + 1,
              results: res.data.size,
              total: res.data.totalElements
            }
          }
        })
        .finally(() => {
          this.loading = false
          this.tableLoading = false
        })
    },
    tableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.listFetch();
    }
  }
}
</script>
