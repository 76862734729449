<template>
  <div>
    <a-modal
      title="门店"
      :width="modalWidth"
      :visible="visible"
      :confirmLoading="loading"
      :maskClosable="false"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <a-button key="back" @click="handleCancel">
          取消
        </a-button>
        <a-button key="submit" :loading="loading" type="primary" @click="handleOk">
          确定
        </a-button>
      </template>
      <a-spin :spinning="loading">
        <a-form :form="form" v-bind="formLayout">
          <a-form-item label="门店名称">
            <a-input
              :autoFocus="true"
              placeholder="请输入门店名称"
              v-decorator="['name', { rules: [{ required: true, message: '请输入门店名称' }] }]"
              allow-clear
              :maxLength="limitLength"
            ></a-input>
          </a-form-item>
          <a-form-item label="门店代码">
            <a-input
              allow-clear
              placeholder="请输入门店码"
              v-decorator="['code', { rules: [{ required: true, message: '请输入门店码' }, { validator: numberValidator }] }]"
              :maxLength="limitLength"
            />
          </a-form-item>
          <a-form-item label="所在地区">
            <a-cascader
              allow-clear
              v-decorator="['area', { rules: [{ required: true, message: '请输入所在地区' }] }]"
              :fieldNames="cityField"
              placeholder="请选择所在地区"
              :options="options"
            />
          </a-form-item>
          <a-form-item label="详细地址">
            <a-input allow-clear placeholder="详细地址" v-decorator="['address']" :maxLength="limitLength" />
          </a-form-item>
          <a-form-item label="门店公司">
            <a-select
              allow-clear
              label-in-value
              showSearch
              placeholder="请选择公司"
              style="width: 100%"
              option-label-prop="label"
              v-decorator="['company', { rules: [{ required: true, message: '请选择公司' }] }]"
              :not-found-content="companyFetching ? undefined : null"
              @change="companyChange"
            >
              <a-spin v-if="companyFetching" slot="notFoundContent" size="small" />
              <a-select-option v-for="item in companyList" :key="item.value" :value="item.value" :label="item.name">
                {{ item.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="店长姓名">
            <a-input
              placeholder="请输入店长姓名"
              v-decorator="['ownerName', { rules: [{ required: true, message: '请输入店长姓名' }, { validator: nameValidator }] }]"
              allow-clear
              :maxLength="limitLength"
            ></a-input>
          </a-form-item>
          <a-form-item label="店长电话">
            <a-input
              allow-clear
              placeholder="请输入店长电话"
              v-decorator="[
                'ownerPhone',
                { rules: [{ required: true, message: '请输入店长电话' }, { validator: phoneValidator }] }
              ]"
              :maxLength="11"
            />
          </a-form-item>
          <a-form-item label="拓展人">
            <a-select
              allow-clear
              label-in-value
              showSearch
              placeholder="请选择拓展人"
              style="width: 100%"
              option-label-prop="label"
              v-decorator="['user', { rules: [{ required: true, message: '请选择拓展人' }] }]"
              :not-found-content="userFetching ? undefined : null"
              @change="userChange"
            >
              <a-spin v-if="userFetching" slot="notFoundContent" size="small" />
              <a-select-option v-for="item in userList" :key="item.value" :value="item.value" :label="item.name">
                {{ item.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>

    <a-modal
      title="确认"
      :width="modalWidth"
      :visible="validatorVisible"
      :confirmLoading="validatorLoading"
      :maskClosable="false"
      @ok="validatorOk"
      @cancel="validatorCancle"
    >
      <template slot="footer">
        <a-button key="back" @click="validatorCancle">
          取消
        </a-button>
        <a-button key="submit" :loading="validatorLoading" type="primary" @click="validatorOk">
          确定
        </a-button>
      </template>
      <p>{{ this.validatorMessage }}</p>
    </a-modal>
  </div>
</template>

<script>
import { shopGet, shopEdit, shopIsExist } from '@/api/shop'
import { companyQuery } from '@/api/company'
import { nobrokerQuery } from '@/api/users'
import { phoneValidator, nameValidator, numberValidator } from '@/utils/util'

export default {
  data() {
    return {
      noticeTitle: '门店管理',
      fields: ['name', 'shopName', 'code', 'area', 'address', 'company', 'user', 'ownerName', 'ownerPhone'],
      form: this.$form.createForm(this),

      visible: false,
      loading: false,
      id: null,
      model: null,
      message: '新建成功',

      userList: [],
      userFetching: false,

      companyList: [],
      companyFetching: false,

      validatorVisible: false,
      validatorLoading: false,
      validatorMessage: '确认继续吗?',

      oldPhone: null,
      newPhone: null
    }
  },
  created() {
    this.formValidator(this.fields)
    this.companyFetch()
    this.nobrokerFetch()
  },
  computed: {
    options() {
      const city = this.defaultCityOptions
      if (this.$store.state.city.city) {
        return this.$store.state.city.city
      } else {
        return city
      }
    }
  },
  methods: {
    // 表单验证
    phoneValidator,
    nameValidator,
    numberValidator,
    showModal(record = null) {
      this.loading = false
      this.model = null
      this.id = null
      this.form.resetFields()
      if (record) {
        this.id = record.id
        this.dataFetch()
      } else {
        this.visible = true
      }
    },
    handleOk() {
      this.message = '新建成功'
      this.form.validateFields((errors, values) => {
        if (!errors) {
          this.loading = true
          const area = values.area
          const param = { ...values, province: area[0], city: area[1], district: area[2], street: area[3] }
          param.user = {
            id: param.user.key.split(',')[1]
          }
          param.company = {
            id: param.company.key.split(',')[1]
          }
          param.shopOwner = {
            name: param.ownerName,
            phone: param.ownerPhone
          }
          delete param.area
          if (this.id) {
            param.id = this.id
            this.message = '编辑成功'
          }
          this.param = param
          console.log(param, 'param')
          this.ownerValidator()
        } else {
          this.loading = false
        }
      })
    },
    validatorOk() {
      this.validatorLoading = true
      this.infoNew()
    },
    handleCancel() {
      this.visible = false
    },
    validatorCancle() {
      this.validatorVisible = false
      this.loading = false
    },
    nobrokerFetch() {
      const userParam = { ...this.maxPagination }
      this.userFetching = true
      nobrokerQuery(userParam)
        .then(res => {
          if (res.success) {
            this.userList = res.data.content.map(item => {
              return {
                name: item.name,
                text: `${item.name}(手机号：${item.phone})`,
                value: `${item.name},${item.id}`
              }
            })
          }
        })
        .finally(() => {
          this.userFetching = false
        })
    },
    companyFetch() {
      const companyParam = { ...this.maxPagination }
      this.companyFetching = true
      companyQuery(companyParam)
        .then(res => {
          if (res.success) {
            this.companyList = res.data.content.map(item => {
              return {
                name: item.name,
                text: `${item.name}(门店代码：${item.code})`,
                value: `${item.name},${item.id}`
              }
            })
          }
        })
        .finally(() => {
          this.companyFetching = false
        })
    },
    dataFetch() {
      this.visible = true
      this.loading = true
      shopGet(this.id)
        .then(res => {
          if (res.id) {
            this.model = res
            this.model.area = [res.province, res.city, res.district, res.street]
            const shopOwner = res._embedded.shopOwner
            const user = res._embedded.user
            const company = res._embedded.company
            this.model.ownerName = shopOwner.name
            this.model.ownerPhone = shopOwner.phone
            this.model.ownerId = shopOwner.id
            const companyObj = {
              key: `${company.name},${company.id}`,
              label: `${company.name}`
            }
            const userObj = {
              key: `${user.name},${user.id}`,
              label: `${user.name}`
            }
            this.oldPhone = shopOwner.phone
            this.model.company = companyObj
            this.model.user = userObj
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    ownerValidator() {
      let paramNew = {
        name: this.param.shopOwner.name,
        phone: this.param.shopOwner.phone
      }
      console.log(paramNew, 'paramNew')
      console.log(this.model, 'this.model')
      if (this.model && this.model.ownerId) {
        const id = this.model.ownerId
        paramNew = { ...paramNew, shopOwnerId: id }
        this.param.shopOwner = { ...this.param.shopOwner, id }
        console.log(paramNew, 'paramNewparamNew');
      } else {
        paramNew = { ...paramNew, shopOwnerId: null }
        this.param.shopOwner = { ...this.param.shopOwner, id: null }
      }
      this.newPhone = this.param.shopOwner.phone
      console.log(paramNew, 'paramNew');
      shopIsExist(paramNew).then(res => {
        if (res.data.result) {
          this.infoNew()
        } else {
          this.validatorMessage = '该店长信息发生变化，是否要继续执行？'
          this.validatorVisible = true
        }
      }).catch(() => {
        this.notiError(this.noticeTitle, '店长姓名、手机号验证出错')
        this.loading = false
      })
    },
    infoNew() {
      this.loading = true
      setTimeout(() => {
        if (this.visible) {
          if (this.oldPhone !== this.newPhone) {
            if (this.param.shopOwner.id) {
              delete this.param.shopOwner.id
            }
          }
          shopEdit(this.param)
            .then(res => {
              if (res.success) {
                this.visible = false
                this.validatorVisible = false
                this.$emit('reload')
                this.notiSuccess(this.noticeTitle, this.message)
              }
            })
            .finally(() => {
              this.loading = false
              this.validatorLoading = false
            })
        }
      }, this.asyncTime)
    },
    userChange(value) {
      this.form.setFieldsValue({
        user: value
      })
    },
    companyChange(value) {
      this.form.setFieldsValue({
        company: value
      })
    }
  }
}
</script>
