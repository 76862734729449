export const columns = [
    {
        title: '门店名称',
        dataIndex: 'name',
        ellipsis: true
    },
    {
        title: '门店代码',
        dataIndex: 'code'
    },
    {
        title: '门店公司',
        dataIndex: 'company.name'
    },
    {
        title: '所在地区',
        dataIndex: 'area',
        ellipsis: true
    },
    {
        title: '详细地址',
        dataIndex: 'address',
        ellipsis: true
    },
    {
        title: '店长姓名',
        dataIndex: 'shopOwner.name'
    },
    {
        title: '店长电话',
        dataIndex: 'shopOwner.phone'
    },
    {
        title: '拓展人',
        dataIndex: 'user.name'
    },
    {
        title: '创建时间',
        dataIndex: 'createTime',
        ellipsis: true,
        sorter: true,
        scopedSlots: { customRender: 'createTime' }
    },
    {
        title: '操作',
        dataIndex: 'action',
        fixed: 'right',
        scopedSlots: { customRender: 'action' }
    }
]
