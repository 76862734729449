<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="门店名称">
                <a-input v-model="queryParam.name" allow-clear placeholder="请输入门店名称" />
              </a-form-item>
            </a-col>
            <a-col v-if="btnPermission('shop_query_id')" :md="8" :sm="24">
              <a-form-item label="拓展人">
                <a-input v-model="queryParam.userName" allow-clear placeholder="请输入拓展人" />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button style="margin-left: 8px" @click="reload('reset')">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="addRow">新建</a-button>
        <a-button type="danger" icon="delete" @click="deleteRow">删除</a-button>
      </div>

      <s-table
        ref="table"
        size="default"
        :rowKey="record => record.id"
        :columns="columns"
        :data="loadData"
        :scroll="defaultScroll"
        showPagination="auto"
        :rowSelection="rowSelection"
        :totalCount="totalCount"
        :alert="true"
      >
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="editInfo(record)">编辑</a>
            <a-divider type="vertical"></a-divider>
            <a @click="lookInfo(record)">查看</a>
          </template>
        </span>
      </s-table>

      <shop-create ref="shopCreate" @reload="reload" />
      <shop-drawer ref="shopDrawer" />
      <shop-delete ref="shopDelete" :selectedRows="selectedRows" @reload="reload('delete')" />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { shopQuery } from '@/api/shop'
import shopCreate from './components/shopCreate'
import shopDrawer from './components/shopDrawer'
import shopDelete from './components/shopDelete'
import { columns } from './js/index'

export default {
  name: 'Shop',
  components: {
    STable,
    Ellipsis,
    shopCreate,
    shopDrawer,
    shopDelete
  },
  data() {
    return {
      columns,
      totalCount: 0,
      noticeTitle: '门店管理',
      queryParam: {},
      selectedRowKeys: [],
      selectedRows: [],
      loadData: parameter => {
        const params = this.paramFormat(parameter)
        Object.assign(params, this.queryParam);
        if (!this.btnPermission('shop_query_id')) {
          params.userId = (this.user.info || {}).id;
        } 
        return shopQuery(params).then(res => {
          const result = this.resFormat(res, 'area')
          this.totalCount = result.totalCount
          return result
        })
      }
    }
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    },
    serial() {
      return this.$store.state.env.serial
    },
    host() {
      return this.$store.state.oss.host
    },
    user() {
       return this.$store.state.user
    }
  },
  
  methods: {
    reload(type = 'reload') {
      if (type === 'reset') this.queryParam = {}
      if (type === 'delete') this.selectedRows = []
      this.tableHome()
      this.$refs.table.refresh()
    },
    addRow() {
      this.$refs.shopCreate.showModal()
    },
    editInfo(record) {
      this.$refs.shopCreate.showModal(record)
    },
    lookInfo(record) {
      this.$refs.shopDrawer.showModal(record)
    },
    deleteRow() {
      const len = this.selectedRows.length
      if (len <= 0) {
        this.$message.warning('请选择要删除的项', 2)
      } else {
        this.$refs.shopDelete.showModal()
      }
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
</script>
